.boltform label.required:after {
    content: " *";
    color: #F00;
    font-weight: bold;
}

p.boltform-debug, p.boltform-error, li.boltform-error, p.boltform-info, p.boltform-message {
    padding: 8px 35px 8px 14px;
    margin-bottom: 20px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

p.boltform-debug {
    border: 1px solid #EED3D7;
    color: #B94A48;
    background-color: #F2DEDE;
}

p.boltform-error {
    border: 1px solid #EED3D7;
    color: #B94A48;
    background-color: #F2DEDE;
}

ul.boltform-error {
    margin-left: 0;
    padding-left: 0;
}

li.boltform-error {
    list-style: none;
    border: 1px solid #EED3D7;
    color: #B94A48;
    background-color: #F2DEDE;
}

p.boltform-message {
    border: 1px solid #D6E9C6;
    color: #468847;
    background-color: #DFF0D8;
}

.boltforms-preview-image {
    display: inline-block;
}


/* Custom MMB form styles */

.survey-form {
    max-width:700px;
    margin:2rem auto;
}
.survey-form .boltforms-row {
    margin: 2rem 0;
    border: 1px solid #bcbfc1;
    background: #f5f5f5;
    border-radius: 5px;
    padding: 2rem;
}
.survey-form .boltforms-label .control-label {
	display: block;
    font-weight: 500;
    margin: 0 0 1rem 0;
    font-size:1.1rem;
}
.survey-form .form-control {
    display:block;
    width: 100%;
    box-sizing:border-box;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
}
.survey-form .form-control:focus {
	border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}
.survey-form .boltforms-value input::placeholder {
	color: #ccc;
}
.survey-form .boltforms-button-row {
    margin:0;
    display: flex;
    justify-content: flex-end;
}
.survey-form .boltforms-button-row .button {
	cursor: pointer;
}
.survey-form #survey_often div:first-child {
	display: none;
}


.submission-form {
  padding:0 2rem;
}
.submission-form .boltforms-row {
    //padding: 1rem 0;
    margin: 0 0 1rem 0;
}
.submission-form .control-label {
    display: block;
    margin: 0 0 1rem 0;
    font-size: 1.4rem;
}
.submission-form input[type="text"], .submission-form input[type="number"], .submission-form input[type="email"], .submission-form textarea {
    display:block;
    width: 100%;
    box-sizing:border-box;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
}
.submission-form input[type="text"]:focus, .submission-form input[type="number"]:focus .submission-form input[type="email"]:focus, .submission-form textarea:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}
.submission-form input::placeholder, .submission-form textarea::placeholder {
  color: #ccc;
}
.submission-form .boltforms-button-row {
    margin:2rem 0;
    display: flex;
    justify-content: center;
}
.submission-form .boltforms-button-row .button {
  cursor: pointer;
}

.submission-form .group1 {
  padding: 2rem 0;
  border-bottom: 1px solid #ccc;
}
.submission-form .group2 {
  display: flex;
  border-bottom: 1px solid #ccc;
}
.submission-form .group2b .boltforms-label {
  display: none;
}
.submission-form .group2a, .submission-form .group2b {
  width: 50%;
  flex-shrink: 0;
  box-sizing: border-box;
  margin:2rem 0;
}
.submission-form .group2a {
  padding:0 2rem 0 0;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
}
.submission-form .group2b {
  padding:0 0 0 2rem;

}
.submission-form .group2c {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.submission-form .group2c>div {
  width: 48%;
  flex-shrink: 0;
}
.submission-form .group3 {
  display: flex;
  border-bottom: 1px solid #ccc;
}
.submission-form .group3a, .submission-form .group3b {
  width: 50%;
  flex-shrink: 0;
  box-sizing: border-box;
  margin:2rem 0;
}
.submission-form .group3a {
  padding:0 2rem 0 0;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
}
.submission-form .group3b {
  padding:0 0 0 2rem;
}

.submission-form .group4 {
  padding:2rem 0;
  border-bottom: 1px solid #ccc;
}
.submission-form .group4a {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.submission-form .group4a .boltforms-label {
  display: none;
}
.submission-form .group4a>div {
  width: 30%;
  flex-shrink: 0;
}
.submission-form .form-hint {
  display: block;
  font-size: 0.8rem;
  font-style: italic;
  margin:-0.5rem 0 1rem 0;
}
#submission_code {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;  
}
#submission_code + label {
  margin:0.5rem 0 0 0;
  max-width: 80%;
  font-size: 1.2rem;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.5rem 1.5rem;
  color:#356eb4;
  border:2px solid #356eb4;
}
#submission_code + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  margin-top: -0.25em;
  margin-right: 0.25em; 
  fill: #356eb4;
}

#submission_code:focus + label,
#submission_code + label:hover {
    background-color: #356eb4;
    color: #fff;
}
#submission_code:focus + label svg,
#submission_code + label:hover svg {
  fill: #fff;
}
#submission_code + label {
  cursor: pointer;
}
#submission_code:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.submission-form .description-example {
  position: relative;
  width:100%;
}
.submission-form .description-example svg {
  width:20px;
  fill: #356eb4;
  cursor: pointer;
  position: absolute;
  top:-2.5rem;
  right:0;
}
.submission-form .description-example .form-hint {
  margin:1rem 0;
  display: none;
}
#submission_description {
  height:150px;
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
  .submission-form {
    padding:0;
  }
  .submission-form .group2 {
    display: block;
  }
  .submission-form .group2a, .submission-form .group2b {
    padding:0;
    border:0;
    width:auto;
  }
  .submission-form .group2c {
    display: block;
  }
  .submission-form .group2c>div {
    width: auto;
  }
  .submission-form .group4a {
    display: block;
  }
  .submission-form .group4a>div {
    width: auto;
  }
  .submission-form .group3 {
    display: block;
  }
  .submission-form .group3a, .submission-form .group3b {
    padding:0;
    border:0;
    width:auto;
  }
}


.control {
  position: relative;
  display: inline-block;
  padding-left: 2.5rem;
  margin:4px 0;
  color: #555;
  cursor: pointer;
}
.control input {
  position: absolute;
  opacity: 0;
  z-index: -1; /* Put the input behind the label so it doesn't overlay text */
}
.control-indicator {
  position: absolute;
  top: .25rem;
  left: 1rem;
  display: block;
  width:  1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 65%;
  color: #eee;
  text-align: center;
  background-color: #e2e2e2;
  background-size: 50% 50%;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* Focus */
.control input:focus ~ .control-indicator {
  box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #356eb4;
}

/* Checked state */
.control input:checked ~ .control-indicator {
  color: #fff;
  background-color: #356eb4;
}

/* Active */
.control input:active ~ .control-indicator {
  color: #fff;
  background-color: #698bd4;
}

/* Checkbox modifiers */
.checkbox .control-indicator {
  border-radius: .25rem;
}
.checkbox input:checked ~ .control-indicator {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
}

/* Radio modifiers */
.radio .control-indicator {
  border-radius: 50%;
}
.radio input:checked ~ .control-indicator {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTQsMUMyLjMsMSwxLDIuMywxLDRzMS4zLDMsMywzczMtMS4zLDMtM1M1LjcsMSw0LDF6Ii8+DQo8L3N2Zz4NCg==);
}